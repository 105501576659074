<template>
  <v-dialog v-model="dialogModifImport" max-width="1000px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("titleVue.articleManagement.modifImport") }}
        </span>
      </v-card-title>
      <v-card-text style="max-height: 700px; overflow-y: auto">
        <Upload @received-file="sendExcel">
          <v-row>
            <v-col cols="12" px-2>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="returnFile"
                class="elevation-1"
                hide-default-footer
              >
                <tr slot="items" slot-scope="props">
                  <td v-if="props.item.result == 'error'">
                    <v-icon color="error darken-2">mdi-cancel</v-icon>
                  </td>
                  <td v-if="props.item.result == 'success'">
                    <v-icon color="success darken-2">
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </td>
                  <td>{{ props.item.filename.substring(0, 30) + "..." }}</td>
                  <td>{{ props.item.articlecode }}</td>
                  <td>{{ props.item.providercode }}</td>
                  <td>{{ props.item.message }}</td>
                </tr>

                <template slot="no-data">
                  <v-alert
                    :value="true"
                    color="info"
                    icon="mdi-information-outline"
                  >
                    <h3>Déposez votre fichier ici</h3>
                  </v-alert>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </Upload>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="isDisabled" color="error" @click.native="close()">{{
          $t("button.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIError, getURLApi } from "../utils";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import Upload from "@/components/Upload";
import router from "../router";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`
});
export default {
  async created() {
    await this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
  },
  props: {
    dialog: { type: Boolean, required: true }
  },
  data() {
    return {
      isDisabled: false,
      uploadPercentage: 0,
      query: false,
      show: false,
      returnFile: [],
      loading: false,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false
        },
        {
          text: router.app.$t("headers.articleManagement.modifImport.filename"),
          align: "center",
          value: "fileName"
        },
        {
          text: router.app.$t(
            "headers.articleManagement.modifImport.articleId"
          ),
          align: "center",
          value: "articlecode"
        },
        {
          text: router.app.$t(
            "headers.articleManagement.modifImport.providerId"
          ),
          align: "center",
          value: "providercode"
        },
        {
          text: router.app.$t("headers.articleManagement.modifImport.status"),
          align: "center",
          value: "message"
        }
      ]
    };
  },
  computed: {
    ...mapState("upload", ["files"]),
    ...mapState("user", ["userInfo", "token"]),
    dialogModifImport: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close");
      }
    }
  },

  methods: {
    ...mapActions("articleManagement", ["setImportModif"]),
    ...mapActions("user", ["getUserInfo"]),
    determineDragAndDropCapable() {
      var div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    sendExcel() {
      if (this.userDroit >= 4) {
        this.loading = true;
        this.isDisabled = true;
        let formData = new FormData();
        formData.append("files", this.files[0].file);
        this.waiting = this.setImportModif(formData)
          .then(data => {
            this.returnFile = data;
            this.loading = false;
            this.isDisabled = false;
            this.$root.$snackBar(this.$t("success.upload"));
          })
          .catch(res => {
            this.loading = false;
            this.isDisabled = false;
            this.$root.$snackBar(this.$t("errors.upload"), {
              color: "error"
            });
          });
      } else {
        this.$root.$snackBar(this.$t("errors.law"), {
          color: "error"
        });
      }
    },
    close() {
      this.dialogModifImport = false;
    }
  },
  components: { Upload }
};
</script>
<style>
h3 {
  margin: 0px auto;
}
</style>
