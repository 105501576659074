<template>
  <v-dialog v-model="dialogReferentiel" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("titleVue.articleManagement.dialogMajReferentiel") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="listIds"
                :label="
                  $t('form.articleManagement.dialogMajReferentiel.listId')
                "
                persistent-hint
                :hint="
                  $t('form.articleManagement.dialogMajReferentiel.listIdHint')
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.native="close()">{{
          $t("button.close")
        }}</v-btn>
        <v-btn color="info" @click.native="dialogReferentiel = false">{{
          $t("button.send")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIError, getURLApi } from "../utils";
import axios from "axios";
import { mapActions } from "vuex";

export default {
  props: {
    dialog: { type: Boolean, required: true }
  },
  data: () => ({
    listIds: "",
    isClose: false
  }),
  computed: {
    dialogReferentiel: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close", this.listIds, this.isClose);
      }
    }
  },
  methods: {
    close() {
      this.isClose = true;
      this.dialogReferentiel = false;
    }
  }
};
</script>
