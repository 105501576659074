<template>
  <v-expansion-panels v-model="extend">
    <v-expansion-panel class="p-0">
      <v-expansion-panel-header color="primary" class="white--text">
        {{ $t("search") }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Promised :promise="promiseListAttribut">
          <template v-slot:pending>
            <v-row justify="center">
              <v-col cols="12">
                <v-progress-linear
                  :size="75"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </template>
          <template v-slot:rejected>
            <v-row justify="center">
              <v-col cols="12">
                <v-alert :value="true" type="error">
                  {{ $t("errors.errorToken") }}
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <v-form
            v-model="valid"
            ref="formSearchArticle"
            @submit.prevent="search"
          >
            <v-row align="start">
              <v-col cols="12" sm="6" md="2" px-2>
                <v-text-field
                  v-model="formData.codeArticle"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.articleCode'
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" d-flex px-2>
                <v-text-field
                  v-model="formData.labelArticle"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.articleLabel'
                    )
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" d-flex px-2>
                <SelectAll
                  :selected.sync="formData.famille"
                  :items="listAttribut.FAMILLE"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t('form.articleManagement.articleManagementForm.family')
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="6" md="4" d-flex px-2>
                <SelectAll
                  :selected.sync="formData.fournisseurs"
                  :items="listAttribut.FOURNISSEUR"
                  multiple
                  search
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t('form.articleManagement.articleManagementForm.provider')
                  "
                  @update:search-input="setCopyProviderId"
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" d-flex px-2>
                <SelectAll
                  :selected.sync="formData.certificats"
                  :items="listAttribut.CERTIFICAT"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.certificate'
                    )
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.filiere"
                  :items="listAttribut.FILIERE"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t('form.articleManagement.articleManagementForm.spinneret')
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.temperature"
                  :items="listAttribut.TEMPERATURE"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.temperature'
                    )
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.paysProduction"
                  :items="listAttribut.PAYSPRODUCTION"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.countryProduct'
                    )
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.departementOrigine"
                  :items="listAttribut.DEPARTEMENTORIGINE"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.originDepartment'
                    )
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.marque"
                  :items="listAttribut.MARQUE"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t('form.articleManagement.articleManagementForm.brand')
                  "
                ></SelectAll>
              </v-col>

              <v-col cols="12" sm="4" md="4" px-2>
                <SelectAll
                  :selected.sync="formData.fabricant"
                  :items="listAttribut.FABRICANT"
                  multiple
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.manufacturer'
                    )
                  "
                ></SelectAll>
              </v-col>
              <v-col cols="12" sm="4" md="4" px-2>
                <v-text-field
                  v-model="formData.pleinText"
                  :rules="[rules.min]"
                  :label="
                    $t('form.articleManagement.articleManagementForm.search')
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="4" sm="4" md="4" px-2>
                <v-checkbox
                  v-model="formData.showDeleted"
                  v-if="userDroit === 5"
                  :label="
                    $t(
                      'form.articleManagement.articleManagementForm.chkShowDeleted'
                    )
                  "
                  data-vv-name="formData.showDeleted"
                  type="checkbox"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto">
                <v-btn @click.native="clear" class="error">
                  {{ $t("button.clear") }}
                  <v-icon right dark>mdi-delete-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn :disabled="loading" type="submit" class="success">
                  {{ $t("button.search") }}
                  <v-icon right dark>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </Promised>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import SelectAll from "@/components/utils/SelectAll";
import { format, addMonths } from "date-fns";

export default {
  async created() {
    this.rules = {
      min: v =>
        v.length >= 4 ||
        v.length == 0 ||
        this.$t("rules.characterMin", { value: 4 }),
      numeric: v => Number.isInteger(v * 1) || this.$t("rules.numericOnly")
    };
    await this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
    this.promiseListAttribut = this.fetchListAttribut();
    await this.promiseListAttribut;
    this.promiseListAttribut.then(r => {
      this.extend = 0;
    });
    //await this.search();
  },
  data: () => ({
    extend: false,
    promiseListAttribut: null,
    userDroit: 0,
    valid: true,
    loading: false,
    formData: {
      codeArticle: "",
      labelArticle: "",
      famille: [],
      fournisseurs: [],
      certificats: [],
      filiere: [],
      temperature: [],
      paysProduction: [],
      departementOrigine: [],
      industriel: [],
      marque: [],
      fabricant: [],
      pleinText: "",
      showDeleted: false,
      dateDebut: "",
      dateFin: ""
    },
    menuDebut: false,
    menuFin: false,
    rules: {}
  }),

  computed: {
    ...mapState("articleManagement", ["listAttribut"]),
    ...mapState("user", ["userInfo"]),
    startDateFormated: {
      get() {
        if (!this.formData.dateDebut) return;
        return this.$d(new Date(this.formData.dateDebut), "formatDate");
      },
      set(val) {
        this.formData.dateDebut = "";
      }
    },
    endDateFormated: {
      get() {
        if (!this.formData.dateFin) return;
        return this.$d(new Date(this.formData.dateFin), "formatDate");
      },
      set(val) {
        this.formData.dateFin = "";
      }
    }
  },

  methods: {
    ...mapActions("articleManagement", ["fetchListAttribut"]),
    ...mapActions("user", ["getUserInfo"]),
    setCopyProviderId: debounce(function(val) {
      if (val) {
        let arrCopy = val
          .replace(/\s/g, ",")
          .split(",")
          .map(t => {
            return parseInt(t);
          });
        this.formData.fournisseurs = this.listAttribut.FOURNISSEUR.filter(
          elem => arrCopy.indexOf(elem.VALUE) !== -1
        ).map(e => e.VALUE);
      }
    }, 150),
    clear() {
      this.formData.codeArticle = "";
      this.formData.labelArticle = "";
      this.formData.famille = [];
      this.formData.fournisseurs = [];
      this.formData.certificats = [];
      this.formData.filiere = [];
      this.formData.temperature = [];
      this.formData.paysProduction = [];
      this.formData.departementOrigine = [];
      this.formData.industriel = [];
      this.formData.marque = [];
      this.formData.fabricant = [];
      this.formData.pleinText = "";
      this.formData.showDeleted = false;
      this.formData.dateDebut = "";
      this.formData.dateFin = "";
      //this.$refs.formSearchArticle.reset();
      setTimeout(() => {
        this.valid = true;
      }, 50);
    },
    search() {
      this.$emit("search", this.formData);
    }
  },

  components: { SelectAll }
};
</script>
