<template>
  <div>
    <v-row>
      <v-col cols="12">
        <ArticleManagementForm
          ref="articleMngForm"
          @search="search"
        ></ArticleManagementForm>
      </v-col>
      <v-col cols="12">
        <ArticleManagementButton
          :userDroit="userDroit"
          :zipExport.sync="canZipExport"
          @import-modif-files="importModifFiles"
          @import-files="importFiles"
          @maj-referentiel="majReferentiel"
          @export-zip-ft="exportZipFt"
          @export-fta="exportFTA"
          @export-provider="exportProvider"
          @purge-article="purgeArticle()"
        />
      </v-col>
      <v-col cols="12">
        <!-- Tableau -->
        <v-data-table
          v-model="selected"
          :headers="headers"
          :loading="loading"
          :options.sync="options"
          :footer-props="{
            'items-per-page-options': rowsPerPageItems,
            'rows-per-page-text': $t('linePage')
          }"
          :server-items-length.sync="listArticle.total"
          :items="listArticle.articles"
          :item-class="color"
          item-key="articleId"
          show-select
          dense
          class="elevation-1 list-article with-datatable"
        >
          <template #[`item.action`]="{ item }">
            <v-btn
              small
              fab
              icon
              class="blue--text"
              @click.native="showArticleInfo(item.articleId)"
              :title="$t('button.articleDetail')"
            >
              <v-icon>mdi-folder-outline</v-icon>
            </v-btn>
            <v-btn
              small
              fab
              icon
              class="red--text"
              v-if="item.isDeleted"
              @click.native="purgeArticle(item.articleId)"
              :title="$t('button.delete')"
            >
              <v-icon>mdi-delete-forever-outline</v-icon>
            </v-btn>
          </template>

          <template slot="pageText" slot-scope="props">
            {{
              $t("lineOf", {
                pageStart: props.pageStart,
                pageStop: props.pageStop,
                itemsLength: props.itemsLength
              })
            }}
          </template>
          <template slot="no-data">
            &nbsp;
            <v-alert
              v-if="listArticle.total === 0"
              :value="true"
              color="error"
              icon="mdi-alert-outline"
              >{{ $t("noData") }}</v-alert
            >
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <ArticleManagementButton
          :userDroit="userDroit"
          :zipExport.sync="canZipExport"
          @import-modif-files="importModifFiles"
          @import-files="importFiles"
          @maj-referentiel="majReferentiel"
          @export-zip-ft="exportZipFt"
          @export-fta="exportFTA"
          @export-provider="exportProvider"
          @purge-article="purgeArticle()"
        />
      </v-col>
    </v-row>

    <ArticleManagementDialogDetail
      v-if="dialogDetail"
      :dialog="dialogDetail"
      :articleId="articleId"
      @refresh="refreshArticles"
    ></ArticleManagementDialogDetail>
    <ArticleManagementMajRefentiel
      v-if="dialogReferentiel"
      :dialog="dialogReferentiel"
      @close="setMajReferentiel"
    ></ArticleManagementMajRefentiel>
    <ArticleManagementImport
      v-if="dialogImport"
      :dialog="dialogImport"
      @close="closeImportDialog"
    ></ArticleManagementImport>
    <ArticleManagementImportReturn
      v-if="dialogImportReturn"
      :dialog="dialogImportReturn"
      :returnFile="returnFile"
      @close="closeImportReturnDialog"
    ></ArticleManagementImportReturn>
    <ArtIcleManagementModifImport
      v-if="dialogModifImport"
      :dialog="dialogModifImport"
      @close="closeModifImportDialog"
    ></ArtIcleManagementModifImport>
    <v-dialog v-model="dialogLoader" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t("loaderMessage") }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import { getURLApi } from "../utils";
import { mapState, mapActions } from "vuex";
import ArticleManagementForm from "@/components/ArticleManagementForm";
import ArticleManagementDialogDetail from "@/components/ArticleManagementDialogDetail";
import ArticleManagementMajRefentiel from "@/components/ArticleManagementMajRefentiel";
import ArticleManagementImport from "@/components/ArticleManagementImport";
import ArticleManagementImportReturn from "@/components/ArticleManagementImportReturn";
import ArtIcleManagementModifImport from "@/components/ArtIcleManagementModifImport";
import ArticleManagementButton from "@/components/ArticleManagementButton";
export default {
  created() {
    this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
  },

  data() {
    return {
      canZipExport: false,
      userDroit: 0,
      articleId: 0,
      listArticleSelected: new Array(),
      returnFile: [],
      //dialog
      dialogDetail: false,
      dialogReferentiel: false,
      dialogImport: false,
      dialogModifImport: false,
      dialogImportReturn: false,
      dialogLoader: false,
      //Donnée Data table
      oldSearch: {},
      loading: false,
      rowsPerPageItems: [50],
      options: {},
      selected: [],
      headers: [
        {
          text: this.$t("headers.articleManagement.articleManagement.id"),
          align: "left",
          value: "codeArticle"
        },
        {
          text: this.$t(
            "headers.articleManagement.articleManagement.denominated"
          ),
          value: "nomArticle"
        },
        {
          text: this.$t("headers.articleManagement.articleManagement.family"),
          value: "famille"
        },
        {
          text: this.$t(
            "headers.articleManagement.articleManagement.countryProduct"
          ),
          value: "paysProduction"
        },
        {
          text: this.$t(
            "headers.articleManagement.articleManagement.temperature"
          ),
          align: "center",
          value: "temperature"
        },
        {
          text: this.$t("headers.articleManagement.articleManagement.upstream"),
          align: "center",
          value: "filiere"
        },
        {
          text: this.$t(
            "headers.articleManagement.articleManagement.fournisseurCount"
          ),
          align: "center",
          width: "20px",
          value: "nbrFournisseur"
        },
        {
          text: this.$t("headers.articleManagement.articleManagement.tsCount"),
          align: "center",
          width: "30px",
          value: "nbrFicheTech"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "left",
          width: "120px",
          class: "min-width-action"
        }
      ]
    };
  },
  computed: {
    ...mapState("articleManagement", ["listArticle"]),
    ...mapState("user", ["userInfo", "token"])
  },

  methods: {
    zipExport() {
      if (
        this.$refs.articleMngForm.formData.codeArticle ||
        this.$refs.articleMngForm.formData.famille.length == 1 ||
        this.$refs.articleMngForm.formData.fournisseurs.length == 1 ||
        this.selected.length
      ) {
        this.canZipExport = true;
      } else {
        this.canZipExport = false;
      }
    },
    ...mapActions("articleManagement", [
      "fetchListArticle",
      "deleteListArticle",
      "articlePurge",
      "sendMajReferentiel"
    ]),
    ...mapActions("FtManagement", ["getZipFile"]),
    ...mapActions("fournisseurManagement", ["fetchExportFournisseurNoFt"]),
    ...mapActions("user", ["getUserInfo"]),
    search(val = null) {
      this.loading = true;
      if (val != null) {
        this.oldSearch = val;
        this.options.page = 1;
      } else {
        val = this.oldSearch;
      }
      this.zipExport();
      this.fetchListArticle({
        codeArticle: val.codeArticle ? val.codeArticle.replace(/\s/g, ",") : "",
        labelArticle: val.labelArticle,
        famille: val.famille ? val.famille.join() : "",
        fournisseurs: val.fournisseurs ? val.fournisseurs.join() : "",
        certificats: val.certificats ? val.certificats.join() : "",
        filiere: val.filiere ? val.filiere.join() : "",
        temperature: val.temperature ? val.temperature.join() : "",
        paysProduction: val.paysProduction ? val.paysProduction.join() : "",
        departementOrigine: val.departementOrigine
          ? val.departementOrigine.join()
          : "",
        industriel: val.industriel ? val.industriel.join() : "",
        marque: val.marque ? val.marque.join() : "",
        fabricant: val.fabricant ? val.fabricant.join() : "",
        pleinText: val.pleinText,
        showDeleted: val.showDeleted,
        dateDebut: val.dateDebut,
        dateFin: val.dateFin,
        page: this.options.page,
        rowNumber: this.options.itemsPerPage,
        multiSort: this.options.multiSort,
        sortBy: this.options.sortBy.join(),
        sortDesc: this.options.sortDesc.join()
      }).then(res => {
        this.loading = false;
      });
    },

    formatDate(val) {
      if (!val) return;
      return this.$d(new Date(val), "formatDate");
    },
    color(item) {
      if (item.isDeleted) return "red--text";
      if (!item.actif) return "blue--text darken-4";
      if (!item.nbrFicheTech) return "orange--text";
      if (item.prixAzero) return "purple--text";
      return "black--text";
    },

    showArticleInfo(articleId) {
      this.dialogDetail = true;
      this.articleId = articleId;
    },
    refreshArticles() {
      this.dialogDetail = false;
      this.search();
    },
    purgeArticle(param) {
      if (param == undefined) {
        param = this.selected.filter(r => r.isDeleted);
      } else param = [param];
      this.$root
        .$confirm(
          this.$t("button.delete"),
          this.$tc("sureArticle", param.length, { count: param.length }),
          {
            color: "error"
          }
        )
        .then(confirm => {
          if (confirm) {
            this.articlePurge({ articleId: param.join() })
              .then(res => {
                this.$root.$snackBar(this.$t("success.delete"));
                this.search();
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
      this.selected = [];
    },
    exportProvider() {
      this.fetchExportFournisseurNoFt().then(res => {
        let url =
          getURLApi() + "cfm/extractionFournisseur.cfm?xlsFile=" + res.xlsFile;
        window.open(url);
      });
    },
    exportFTA() {
      axios
        .post(
          getURLApi() + "cfcs/listeArticles.cfc?method=exportFta",
          qs.stringify({
            param: JSON.stringify({
              codeArticle: this.$refs.articleMngForm.formData.codeArticle,
              labelArticle: this.$refs.articleMngForm.formData.labelArticle,
              famille: this.$refs.articleMngForm.formData.famille.join(),
              fournisseurs: this.$refs.articleMngForm.formData.fournisseurs.join(),
              certificats: this.$refs.articleMngForm.formData.certificats.join(),
              filiere: this.$refs.articleMngForm.formData.filiere.join(),
              temperature: this.$refs.articleMngForm.formData.temperature.join(),
              paysProduction: this.$refs.articleMngForm.formData.paysProduction.join(),
              departementOrigine: this.$refs.articleMngForm.formData.departementOrigine.join(),
              industriel: this.$refs.articleMngForm.formData.industriel.join(),
              marque: this.$refs.articleMngForm.formData.marque.join(),
              fabricant: this.$refs.articleMngForm.formData.fabricant.join(),
              pleinText: this.$refs.articleMngForm.formData.pleinText,
              showDeleted: this.$refs.articleMngForm.formData.showDeleted,
              dateDebut: this.$refs.articleMngForm.formData.dateDebut,
              dateFin: this.$refs.articleMngForm.formData.dateFin
            }),
            articleId: this.selected.map(r => r.articleId).join()
          }),
          {
            headers: {
              Authorization: this.token
            }
          }
        )
        .then(res => {
          let url =
            getURLApi() + "cfm/extractionFTA.cfm?xlsFile=" + res.data.xlsFile;
          window.open(url);
        });
    },
    exportZipFt() {
      let param = {};
      if (this.selected.length) {
        param = { listArticle: this.selected.map(r => r.articleId).join() };
      } else {
        let articles = this.$refs.articleMngForm.formData.codeArticle;
        let famille = this.$refs.articleMngForm.formData.famille;
        let fournisseurs = this.$refs.articleMngForm.formData.fournisseurs;
        param = {
          codeArticle: articles ? articles.replace(/\s/g, ",") : "",
          famille: famille ? famille.join() : "",
          fournisseurs: fournisseurs ? fournisseurs.join() : ""
        };
      }
      this.$root.$overlay.show();
      this.getZipFile(param)
        .then(res => {
          this.$root.$overlay.hide();
          this.$root.$snackBar(this.$t("success.export"));
          let url = getURLApi() + "exportZip.cfm?zipFile=" + res.file;
          window.open(url);
        })
        .catch(err => {
          this.$root.$overlay.hide();
          this.$root.$snackBar(this.$t("errors.export"), {
            color: "error"
          });
        });
    },
    importModifFiles() {
      this.dialogModifImport = true;
    },
    importFiles() {
      this.dialogImport = true;
    },
    majReferentiel() {
      this.dialogReferentiel = true;
      this.listArticleSelected = new Array();
      this.selected.forEach(element => {
        this.listArticleSelected.push(element["articleId"]);
      });
      this.selected = [];
    },
    setMajReferentiel(listIds, isClose) {
      if (isClose) {
        this.dialogReferentiel = false;
      } else {
        this.dialogReferentiel = false;
        this.dialogLoader = true;
        if (listIds != "") {
          var ids = listIds.split(",");
          ids.forEach(element => {
            this.listArticleSelected.push(element);
          });
        }
        this.sendMajReferentiel({
          token: this.token,
          param: this.listArticleSelected
        })
          .then(res => {
            this.dialogLoader = false;
            this.$root.$snackBar(this.$t("success.Referentiel"));
          })
          .catch(res => {
            this.dialogLoader = false;
            this.$root.$snackBar(this.$t("errors.Referentiel"), {
              color: "error"
            });
          });
      }
    },
    closeModifImportDialog() {
      this.dialogModifImport = false;
    },
    closeImportDialog(returnFile, isClose) {
      if (isClose) {
        this.dialogImport = false;
      } else {
        this.dialogImport = false;
        this.returnFile = returnFile;
        this.search();
        this.dialogImportReturn = true;
      }
    },
    closeImportReturnDialog() {
      this.dialogImportReturn = false;
    }
  },
  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    },
    selected: function() {
      this.zipExport();
    }
  },
  components: {
    ArticleManagementForm,
    ArticleManagementDialogDetail,
    ArticleManagementMajRefentiel,
    ArticleManagementImport,
    ArticleManagementImportReturn,
    ArtIcleManagementModifImport,
    ArticleManagementButton
  }
};
</script>

<style scoped>
.list-article >>> .v-datatable th:last-child,
.min-width-action {
  min-width: 120px !important;
  max-width: 120px !important;
}
li.v-expansion-panel__container {
  background: inherit !important;
}

.with-datatable {
  width: calc(100vw - 48px);
}

@media only screen and (max-width: 959px) {
  .with-datatable {
    width: calc(100vw - 32px);
  }
}
</style>
