<template>
  <v-row justify="end">
    <v-col cols="auto">
      <v-btn
        color="error"
        class="d-inline"
        v-if="userDroit === 5"
        v-on:click="$emit('purge-article')"
      >
        {{ $t("button.deleteAll") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        class="d-inline"
        v-if="userDroit >= 1"
        v-on:click="$emit('export-provider')"
      >
        {{ $t("button.exportProvider") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        class="d-inline"
        v-if="userDroit >= 1"
        v-on:click="$emit('export-fta')"
      >
        {{ $t("button.exportAll") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        :disabled="!zipExport"
        color="primary"
        class="d-inline"
        v-if="userDroit >= 1"
        v-on:click="$emit('export-zip-ft')"
      >
        {{ $t("button.exportZip") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        class="d-inline"
        v-if="userDroit === 5"
        v-on:click="$emit('maj-referentiel')"
      >
        {{ $t("button.majReferentiel") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        class="d-inline"
        v-if="userDroit >= 4"
        v-on:click="$emit('import-files')"
      >
        {{ $t("button.importFTA") }}
      </v-btn>
    </v-col>
    <v-col cols="auto">
      <v-btn
        color="primary"
        class="d-inline"
        v-if="userDroit >= 4"
        v-on:click="$emit('import-modif-files')"
        >{{ $t("button.importModifFTA") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ArticleManagementButton",
  created() {},

  props: {
    userDroit: { type: Number, required: true },
    zipExport: { type: Boolean, required: true }
  },
  data() {
    return {};
  },

  computed: {},

  methods: {}
};
</script>
