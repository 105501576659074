<template>
  <v-dialog v-model="dialogImport" scrollable persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("titleVue.articleManagement.Import") }}
        </span>
      </v-card-title>
      <v-card-text>
        <div id="file-drag-drop">
          <form ref="fileform">
            <v-row>
              <v-col cols="12" px-2>
                <v-checkbox
                  v-model="dataForm.isFta"
                  :label="$t('form.articleManagement.import.chkIsFta')"
                  data-vv-name="dataForm.isFta"
                  type="checkbox"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" px-2>
                <SelectAll
                  ref="selectFournisseur"
                  :disabled="dataForm.isFta"
                  :selected.sync="dataForm.fournisseurs"
                  :items="listAttribut.FOURNISSEUR"
                  multiple
                  dense
                  search
                  item-text="TEXT"
                  item-value="VALUE"
                  :label="$t('form.articleManagement.import.provider')"
                  @update:search-input="setCopyProviderId"
                  @paste="onPasteFournisseur"
                ></SelectAll>
              </v-col>
              <v-col cols="12" px-2>
                <v-progress-linear
                  v-model="uploadPercentage"
                  :active="show"
                  :indeterminate="query"
                  :query="true"
                ></v-progress-linear>
              </v-col>
              <v-col cols="12" px-2>
                <div v-if="show">
                  <v-subheader>
                    {{
                      $t("importMass.fileUpload", {
                        fileSend: nbFileSend,
                        fileTotal: nbFileTot
                      })
                    }}
                  </v-subheader>
                </div>
                <v-alert
                  :value="true"
                  color="info"
                  icon="mdi-information-outline"
                >
                  <h3>Déposez vos fichiers ici</h3>
                </v-alert>
              </v-col>
              <v-col cols="12" px-2>
                <v-list>
                  <v-list-item
                    v-for="(file, key) in files"
                    :key="key"
                    class="file-listing"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="file.name"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        :disabled="isDisabled"
                        icon
                        ripple
                        v-on:click="removeFile(key)"
                      >
                        <v-icon color="error">
                          mdi-delete-forever-outline
                        </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </form>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="isDisabled" color="error" @click.native="close()">{{
          $t("button.close")
        }}</v-btn>
        <v-btn
          :disabled="
            files.length < 1 ||
              (dataForm.fournisseurs == '' && !dataForm.isFta) ||
              isDisabled
          "
          color="info"
          @click.native="sendFile()"
          >{{ $t("button.send") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIError, getURLApi } from "../utils";
import axios from "axios";
import { debounce } from "lodash";
import SelectAll from "@/components/utils/SelectAll";
import { mapState, mapActions } from "vuex";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`
});
export default {
  async created() {
    await this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
    this.promiseListAttribut = this.fetchListAttribut();
    await this.promiseListAttribut;
  },
  props: {
    dialog: { type: Boolean, required: true }
  },
  data: () => ({
    nbFileSend: 0,
    nbFileTot: 0,
    dragAndDropCapable: false,
    files: [],
    isDisabled: false,
    uploadPercentage: 0,
    isClose: false,
    dataForm: {
      isFta: false,
      fournisseurs: []
    },
    returnFile: [],
    query: false,
    show: false
  }),
  computed: {
    ...mapState("articleManagement", ["listAttribut"]),
    ...mapState("user", ["userInfo", "token"]),
    dialogImport: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close", this.returnFile, this.isClose);
      }
    }
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if (this.dragAndDropCapable) {
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop"
      ].forEach(
        function(evt) {
          this.$refs.fileform.addEventListener(
            evt,
            function(e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );
      this.$refs.fileform.addEventListener(
        "drop",
        function(e) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.files.push(e.dataTransfer.files[i]);
          }
        }.bind(this)
      );
    }
  },

  methods: {
    ...mapActions("articleManagement", ["fetchListAttribut"]),
    ...mapActions("user", ["getUserInfo"]),
    onPasteFournisseur() {
      setTimeout(() => {
        this.$refs.selectFournisseur.$refs.select.blur();
      }, 500);
    },
    setCopyProviderId: debounce(function(val) {
      if (val) {
        let arrCopy = val
          .replaceAll(/[\n\r\s]/g, ",")
          .split(",")
          .map(t => {
            return parseInt(t);
          });
        this.dataForm.fournisseurs = this.listAttribut.FOURNISSEUR.filter(
          elem => arrCopy.indexOf(elem.VALUE) !== -1
        ).map(e => e.VALUE);
      }
    }, 150),
    determineDragAndDropCapable() {
      var div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },

    submitFiles(fileSend) {
      return new Promise(resolve => {
        let formData = new FormData();
        fileSend.forEach((file, index) => {
          formData.append("files[" + index + "]", file);
        });

        cfc
          .post("/listeArticles.cfc", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: this.token
            },
            params: {
              method: "uploadFiles",
              isFta: this.dataForm.isFta,
              fournisseursId: this.dataForm.fournisseurs
                ? this.dataForm.fournisseurs.join()
                : ""
            },
            onUploadProgress: function(progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              if (this.uploadPercentage == 100) {
                this.query = true;
              }
            }.bind(this)
          })
          .then(data => {
            this.nbFileSend += fileSend.length;
            resolve(data.data);
          });
      });
    },
    sendFile() {
      this.isDisabled = true;
      this.show = true;
      this.nbFileTot = this.files.length;
      let nbrFile = Math.ceil(this.files.length / 5);
      const promises = [];
      for (let i = 0; i <= nbrFile; i++) {
        if (this.files.length > 0) {
          let fileSend = this.files.splice(0, 5);
          promises.push(this.submitFiles(fileSend));
        }
      }
      let res = Promise.all(promises);
      res.then(result => {
        this.returnFile = result.flat();
        this.finishUpload();
      });
    },
    finishUpload() {
      this.query = false;
      this.show = false;
      this.dialogImport = false;
    },
    removeFile(key) {
      this.files.splice(key, 1);
    },
    close() {
      this.isClose = true;
      this.dialogImport = false;
    }
  },
  components: { SelectAll }
};
</script>
<style>
h3 {
  margin: 0px auto;
}
</style>
