<template>
  <v-dialog
    v-model="dialogDetail"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    scrollable
  >
    <v-card>
      <Upload @received-file="createFTA">
        <div id="file-drag-drop">
          <v-toolbar dark color="primary">
            <v-toolbar-title>
              {{ $t("titleVue.articleManagement.dialogDetail") }} -
              {{ articleId }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click.native="dialogDetail = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="px-5 py-3">
            <v-row align="center">
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.articleName"
                  :label="$t('form.articleManagement.dialogDetail.name')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.articleDesc"
                  :label="$t('form.articleManagement.dialogDetail.description')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.familleLibelle"
                  :label="$t('form.articleManagement.dialogDetail.family')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.marqueLibelle"
                  :label="$t('form.articleManagement.dialogDetail.brand')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.fabricantLibelle"
                  :label="
                    $t('form.articleManagement.dialogDetail.manufacturer')
                  "
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.departementOrigineLibelle"
                  :label="
                    $t('form.articleManagement.dialogDetail.originDepartment')
                  "
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.paysProductionLibelle"
                  :label="
                    $t('form.articleManagement.dialogDetail.countryProduct')
                  "
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.temperatureLibelle"
                  :label="$t('form.articleManagement.dialogDetail.temperature')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.certificatLibelle"
                  :label="$t('form.articleManagement.dialogDetail.certificate')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.filiereLibelle"
                  :label="$t('form.articleManagement.dialogDetail.upstream')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.userCreate"
                  :label="$t('form.articleManagement.dialogDetail.user')"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="2" class="px-1">
                <v-text-field
                  v-model="articleInfo.articleDateCreate"
                  :label="$t('form.articleManagement.dialogDetail.createDate')"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-layout>
              <v-col sm12>
                <v-data-table
                  :loading="loading"
                  :headers="headersDetail"
                  :items="articleDT"
                  class="elevation-1"
                  :footer-props="{
                    'rows-per-page-text': $t('linePage')
                  }"
                >
                  <template #[`item.fournisseurActif`]="{ item }">
                    <v-icon
                      v-if="item.fournisseurId !== 0"
                      :class="
                        item.fournisseurActif != 1 ? 'red--text' : 'green--text'
                      "
                      >{{
                        item.fournisseurActif == 1
                          ? "mdi-checkbox-marked-circle-outline"
                          : "mdi-alert-circle-outline"
                      }}</v-icon
                    >
                  </template>
                  <template #[`item.action`]="{ item }">
                    <v-btn
                      small
                      fab
                      icon
                      class="blue--text ma-1"
                      v-if="userDroit >= 4"
                      @click.native="
                        modifFichTech(
                          item.fournisseurId,
                          item.ficheTechId,
                          item.isFta
                        )
                      "
                      :title="$t('button.edit')"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      fab
                      icon
                      class="blue--text ma-1"
                      @click.native="exporter(item.ficheTechId)"
                      :title="$t('button.download')"
                    >
                      <v-icon>mdi-cloud-download-outline</v-icon>
                    </v-btn>
                    <v-btn
                      small
                      fab
                      icon
                      class="red--text ma-1"
                      v-if="userDroit >= 4"
                      @click.native="
                        deleteArticle(item.fournisseurId, item.isFta)
                      "
                      :title="$t('button.delete')"
                    >
                      <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                  </template>

                  <template slot="pageText" slot-scope="props">
                    {{
                      $t("lineOf", {
                        pageStart: props.pageStart,
                        pageStop: props.pageStop,
                        itemsLength: props.itemsLength
                      })
                    }}
                  </template>
                  <template slot="no-data">
                    <v-alert
                      :value="true"
                      color="error"
                      icon="mdi-comment-alert-outline"
                    >
                      {{ $t("noData") }}
                    </v-alert>
                  </template>
                </v-data-table>
              </v-col>
            </v-layout>
          </div>
        </div>
      </Upload>
      <ArticleManagementDialogDetailFT
        v-if="dialogEdit"
        :dialog.sync="dialogEdit"
        :articleId="articleId"
        :fournisseurId="fournisseurId"
        :famille="articleInfo.familleLibelle"
        :ficheTechId="ficheTechId"
        :version="version"
        :fileName="fileName"
        :isCreate="isCreate"
        :isFta="isFta"
        :hasFta="hasFta"
        @created="createFTA"
        @close="closeFTA"
        @refresh="getArticles"
      ></ArticleManagementDialogDetailFT>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIError, getURLApi } from "../utils";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import ArticleManagementDialogDetailFT from "@/components/ArticleManagementDialogDetailFT";
import Upload from "@/components/Upload";
import debounce from "lodash/debounce";
export default {
  created() {
    this.getArticleInfo({ articleId: this.articleId });
    this.getArticles();
    this.getUserInfo().then(red => {
      this.userDroit = this.userInfo["droit"];
    });
  },

  props: {
    dialog: { type: Boolean, required: true },
    articleId: { type: Number, required: true }
  },
  data() {
    return {
      userDroit: 0,
      params: {},
      active: false,
      dialogEdit: false,
      isCreate: false,
      fileName: "",
      version: 0,
      ficheTechId: 0,
      isFta: false,
      hasFta: false,
      fournisseurId: 0,
      loading: false,

      headersDetail: [
        {
          text: this.$t("headers.articleManagement.dialogDetail.file"),
          align: "center",
          value: "fileName",
          sortable: false
        },
        {
          text: this.$t("headers.articleManagement.dialogDetail.desc"),
          align: "center",
          value: "description",
          sortable: false
        },
        {
          text: this.$t("headers.articleManagement.dialogDetail.provider"),
          align: "center",
          value: "fournisseur"
        },
        {
          text: this.$t("headers.articleManagement.dialogDetail.active"),
          align: "center",
          value: "fournisseurActif"
        },
        {
          text: this.$t("headers.articleManagement.dialogDetail.updateDate"),
          align: "center",
          value: "dateMaj"
        },
        {
          text: "Action",
          align: "center",
          value: "action",
          sortable: false,
          width: "200px"
        }
      ]
    };
  },
  watch: {
    dialogEdit: function(onOpen) {
      if (onOpen == false) {
        this.getArticles();
      }
    }
  },
  computed: {
    ...mapState("articleManagement", ["articleInfo", "articleDT"]),
    ...mapState("upload", ["files"]),
    ...mapState("user", ["userInfo"]),
    dialogDetail: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("refresh", val);
      }
    },
    urlApi() {
      return getURLApi() + "/cfcs";
    }
  },

  methods: {
    ...mapActions("articleManagement", [
      "getArticleInfo",
      "getArticleInfoDT",
      "deleteArticleFT"
    ]),
    ...mapActions("user", ["getUserInfo"]),
    modifFichTech(fournisseurId, ficheTechId, isFta) {
      this.ficheTechId = ficheTechId;
      this.fournisseurId = fournisseurId;
      this.isFta = isFta;
      this.dialogEdit = true;
    },
    exporter(ficheTechId) {
      window.open(getURLApi() + "getFta.cfm?ftaId=" + ficheTechId);
    },
    deleteArticle(fournisseurId, isFta) {
      this.$root
        .$confirm(this.$t("button.delete"), this.$tc("sureArticle", 1), {
          color: "error"
        })
        .then(confirm => {
          if (confirm) {
            this.deleteArticleFT({
              articleId: this.articleId,
              fournisseurId,
              isFta: isFta
            })
              .then(data => {
                this.$root.$snackBar(this.$t("success.delete"));
                this.getArticles();
              })
              .catch(res => {
                this.$root.$snackBar(this.$t("errors.delete"), {
                  color: "error"
                });
              });
          }
        });
    },
    getArticles() {
      this.dialogEdit = false;
      this.loading = true;
      this.getArticleInfoDT({ articleId: this.articleId })
        .then(data => {
          this.articleDT.forEach(element => {
            if (element["isFta"]) {
              this.hasFta = true;
            }
          });
          this.loading = false;
        })
        .catch(res => {
          this.loading = false;
        });
    },
    createFTA(val) {
      if (this.userDroit >= 4) {
        this.dialogEdit = false;
        if (val && this.files.length) {
          setTimeout(() => {
            this.isCreate = true;
            this.fileName = this.files[0].name;
            this.dialogEdit = true;
          }, 500);
        } else if (!val) {
          this.isCreate = true;
          this.fileName = this.files[0].name;
          this.dialogEdit = true;
        } else {
          this.isCreate = false;
          this.fileName = "";
          this.getArticles();
        }
      } else {
        this.$root.$snackBar(this.$t("errors.law"), {
          color: "error"
        });
      }
    },
    closeFTA() {
      this.isCreate = false;
      this.fileName = "";
      this.getArticles();
    }
  },

  components: { ArticleManagementDialogDetailFT, Upload }
};
</script>
