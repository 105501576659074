<template>
  <v-dialog
    v-model="dialogImportReturn"
    scrollable
    persistent
    max-width="700px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("titleVue.articleManagement.ImportReturn") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          height="500px"
          :items="returnFile"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          fixed-header
          dense
        >
          <template #[`item.icon`]="{ item }">
            <v-icon color="success darken-2" v-if="item.result == 'success'">
              mdi-checkbox-marked-circle-outline
            </v-icon>
            <v-icon color="error darken-2" v-else>mdi-cancel</v-icon>
          </template>
          <template #[`item.result`]="{ item }">
            <span v-if="item.result == 'errorLiaisonUnknow'">
              {{ $t("errors.linkUnknow") }}
            </span>
            <span v-else-if="item.result == 'errorArticleUnknow'">
              {{ $t("errors.articleUnknow") }}
            </span>
            <span v-else-if="item.result == 'errorArticleFormat'">
              {{ $t("errors.articleFormat") }}
            </span>
            <span v-else-if="item.result == 'success'">
              {{ $t("success.upload") }}
            </span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.native="close()">{{
          $t("button.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APIError, getURLApi } from "../utils";
import axios from "axios";
import { mapState, mapActions } from "vuex";
import router from "../router";
export default {
  async created() {},
  props: {
    dialog: { type: Boolean, required: true },
    returnFile: { type: Array, required: true }
  },
  data: () => ({
    isClose: false,
    headers: [
      {
        text: "",
        align: "left",
        value: "icon",
        sortable: false
      },
      {
        text: router.app.$t("headers.articleManagement.importReturn.file"),
        align: "center",
        value: "fileName"
      },
      {
        text: router.app.$t("headers.articleManagement.importReturn.status"),
        align: "center",
        value: "result"
      }
    ]
  }),
  computed: {
    dialogImportReturn: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit("close", this.isClose);
      }
    }
  },
  methods: {
    close() {
      this.isClose = true;
      this.dialogImportReturn = false;
    }
  }
};
</script>
